import React from 'react'
import { Box, Text, Flex, Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon } from "@chakra-ui/react"
import { CollapsablePanel } from './CollapsablePanel'
export const  CollapsableUpdates = ( {country, proposal} ) => {

  return (
    <>
        {country.proposals.map((innerProposal, index) => (
            <div data-id={proposal}>
            {
                innerProposal.actions.length > 0 && proposal === innerProposal.id
                ?   
                    <Flex key={index} >
                        {
                            <Accordion defaultIndex={[1]} allowMultiple>
                                <AccordionItem border="none" outline="none" m="20px 0 0">
                                    <AccordionButton p="0" _hover={{background: "none"}} w="auto">
                                        <Box flex='1' textAlign='left' border="none" >
                                        <Text
                                            color="#282828"
                                            position="relative"
                                            fontSize="sm"
                                        >
                                            <Text as="span" top='-4px' position="relative" left="0" bottom="0" fontWeight="500">Show Updates</Text>
                                            <Text as="span" h="3px" w="100%" bg="#000000" color="transparent" position="absolute" left="0" bottom="0">.</Text>
                                        </Text>
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                
                                    <AccordionPanel p="0">
                                        {innerProposal.actions.map((propAction, index) => (
                                            <CollapsablePanel propAction={propAction} proposalsLength={innerProposal.actions.length} key={index} />
                                        ))}
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                        }
                    </Flex>
                : ""
            }
            </div>
        ))} 
    </>
  )
}
