import React from 'react'
import { Box, Divider, Text, Flex, Icon,
    Popover,
    PopoverTrigger,
    Button,
    PopoverContent,
    PopoverBody, } from "@chakra-ui/react"
import { BiLinkExternal } from "react-icons/bi"
import { Link } from "gatsby"

export const CollapsablePanel = ({propAction, key, proposalsLength}) => {
  return (
    <Box key={key}>
        <Flex direction="column" justifyContent="flex-start" alignItems="flex-start" >
            <Box  mt="5px" w="100%">
                <Text fontWeight="600" fontSize="sm" mt="8px">
                {propAction.actionDate}
                </Text> 
                {propAction.description.internal.content && (
                    <Flex alignItems="center" justifyContent="space-between" w="100%">
                        <Text fontWeight="400" fontSize="sm">
                            {propAction.description.internal.content}
                        </Text>
                        
                        {
                            propAction.links && propAction.links.length > 0
                            ?
                                <>
                                    <Popover trigger="hover" isLazy>
                                        <PopoverTrigger>
                                        <Button position="relative" top="0px" bg="transparent" _hover={{background: "transparent"}}>
                                            <Icon  className="share__icon" as={BiLinkExternal}/>
                                        </Button>
                                        </PopoverTrigger>
                                        <PopoverContent p="10px">
                                        <PopoverBody>
                                        { propAction.links.length > 0 &&
                                            propAction.links.map((link, i) => (
                                            <Box key={`${propAction.id}_${i}`}>
                                                <Link
                                                noOfLines={1}
                                                display="-webkit-box"
                                                href={link}
                                                target="_blank"
                                                title="View News Source"
                                                color="blue"
                                                >
                                                <Text fontSize="xs" >
                                                    {link}
                                                </Text>
                                                <Divider m="5px 0" />
                                                </Link>
                                            </Box>
                                            ))}
                                        </PopoverBody>
                                        </PopoverContent>
                                    </Popover>
                                </>
                            : ""
                        }
                    </Flex>
                )}
            </Box>
        </Flex> 
        
        {
            key < proposalsLength - 1
            ? <Divider m="5px 0" /> 
            : ""
        }
    </Box>
  )
}
